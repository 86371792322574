import React, { useState, useEffect } from 'react';
import { Svg } from 'kh-common-components';
import { LinkAnchor } from 'components/common/LinkAnchor';

function FormErrors({errorlist}){

    let errorCount = 0;

    if(errorlist!==undefined && errorlist!==null){
        errorCount = errorlist.length;
    }

return( errorCount>0 && 
        <React.Fragment>
            <div className="mer-form-validation-summary">
                          
                <ul className="mer-form-validation-summary__list">
                    {
                        errorlist.map(function (error, i) {
                            const liClassName = 'mer-form-validation-summary__item' + (error.isResolved ? ' mer-form-validation-summary__item--status-success' : '');
                            return (<li key={i} className={liClassName}>
                                        <LinkAnchor 
                                            to={'/service-availability#' + error.fieldName}
                                            title={error.errorStr}
                                            // stripHash={true} 
                                            offset={-100} 
                                            className=""
                                        />
                                        {!error.isResolved && 
                                         <Svg title="Alert" 
                                         col="mer-svg-container"
                                         icon="alert-triangle" />  
                                            
                                        }
                                        {error.isResolved && 
                                         <Svg title="Success" 
                                         col="mer-svg-container"
                                         icon="tick-circle-fill" />  
                                            
                                        }
                                    </li>);
                        })
                    }
                </ul>
            </div>
        </React.Fragment>
    );
}

export default FormErrors;