import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';

import heroSave10 from 'images/hero-save10.png';

function LpgOrderInfo (props) {

  return (
    <LayoutPanel background={props.panelBackground}
                 padding={props.panelPadding} 
                 {...props}>
      <div className="container">
        <div className="row mer-panel">
          <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
              <img src={heroSave10} 
                   className="mer-panel__image"
                   alt="Order online and Save 10%*" />
          </div>
          <div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
              <h3>Order online and save 10%</h3>
              <p>Existing residential and business customers can <a href="/lpg/order-online">login to order cylinders online</a> and save 10% off your order.</p>
              <div>
                <LinkModal {...props}
                    modalContents={modalContents}
                    useModal="true"
                    // linkClass=""
                    displayContent={<small>Terms and conditions apply</small>}
                    title="Save 10% when you order online"
                    modalTitle="Save 10% when you order online"
                    //icon="question-circle"
                    //iconClass="mer-svg-container mer-svg-container--align-center mer-svg-container--width-xs mer-fill--primary-blue"
                    modalContentName="lpg_order_terms"
                    okayButtonLabel="Okay"
                />
              </div>
          </div>
        </div>
      </div>
    </LayoutPanel>
  )
}

export default LpgOrderInfo;