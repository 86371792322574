import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';

import heroMovingBox from 'images/hero-moving-box.png';

function NewConnectionInfo (props) {

  return (
    <LayoutPanel background="mer-bg--ui-light"
                  padding="mer-py-lg">
      <div className="container">
        <div className="row mer-panel">
          <div className="col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 order-sm-2 content">
              <h2>Building or connecting to natural gas for the first time?</h2>
              <p>Take the legwork out of connecting your new house to natural gas. It’s less hassle, more value.</p>
              <div className="mer-panel__actions mer-button-group">
                  <a href="/sign-up" className="mer-button mer-button--primary">Get connected</a>
              </div>
          </div>

          <div className="col-10 col-lg-4 col-sm-4 order-sm-1 mer-panel__images">
              <img src={heroMovingBox} 
                   className="mer-panel__image"
                   alt="Moving box" />
          </div>
        </div>
      </div>
    </LayoutPanel>
  )
}

export default NewConnectionInfo;