import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';

import lpg30 from 'images/product-lpg30.png';

function SignupLpgInfo (props) {

  return (
    <LayoutPanel background="mer-bg--primary-cyan"
                  theme="mer-theme--cyan"
                  padding="mer-py-lg"
                  {...props}>
        <div className="container">
            <div className="row mer-panel">

              <div className="col-10 col-xl-5 col-md-6 col-sm-7 order-sm-2 content">
                  <h1>Save 30% off your first cylinder order.</h1>
                  <h4>PLUS no equipment service charge for 12 months*!</h4>
                  <div>
                    <LinkModal {...props}
                        modalContents={modalContents}
                        useModal="true"
                        displayContent={<small>Terms and conditions apply</small>}
                        title="New Customer Offer – Terms & Conditions"
                        modalTitle="New Customer Offer – Terms & Conditions"
                        modalContentName="lpg_acquisition_offer"
                        okayButtonLabel="Okay"
                    />
                  </div>
                  <div className="mer-panel__actions mer-button-group">
                      <a href="/sign-up" className="mer-button mer-button--primary">Sign up &amp; order</a>
                  </div>
              </div>

              <div className="col-10 col-sm-5 order-sm-1 mer-panel__images">
                  <img src={lpg30} 
                       className="mer-panel__image"
                       alt="Save 30%*" />
              </div>

            </div>
        </div>
    </LayoutPanel>
  )
}

export default SignupLpgInfo;