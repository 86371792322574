import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';

import productCylinders from 'images/product-cylinders.png';

function LpgCyclinderInfo (props) {

  return (
    <LayoutPanel background={props.panelBackground}
                  padding={props.panelPadding}
                  id="lpg-cyliner-info">
      <div className="container">
        <div className="row mer-panel">
          <div className="col-10 col-xl-5 col-md-6 col-sm-7 content">
              <h2>Need gas cylinders for your home? We've got you.</h2>
              <p>As the local name in LPG, we deliver gas cylinders to more places more often across WA and the NT. Choose local for safe and reliable service, plus save 10% with each online order.</p>
              <div className="mer-panel__actions mer-button-group">
                  <a href="/lpg" className="mer-button mer-button--primary">Our cylinder range</a>
                  <a href="/locations" className="mer-button mer-button--secondary">Find a retailer</a>
              </div>
          </div>

          <div className="col-10 col-lg-3 col-sm-4 offset-sm-1 mer-panel__images">
              <img src={productCylinders} 
                   className="mer-panel__image"
                   alt="LPG gas bottle cylinders" />
          </div>
        </div>
      </div>      
    </LayoutPanel>
  )
}

export default LpgCyclinderInfo;