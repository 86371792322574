import React, { useState, useEffect } from 'react';
import { isAfter } from "date-fns";

import { Redirect } from "@reach/router";
import { GtmHelper, getCookieValue, deleteCookie, setCookie } from 'kh-common-components';

import Layout from "components/layout/layoutMain";
import LayoutPanel from 'components/layout/layoutPanel';
import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";
import TileCard from 'components/common/TileCard';
import tileCardData_ng from 'constants/service-availability/tile-card-ng';
import tileCardData_lpg from 'constants/service-availability/tile-card-lpg';
import Link from 'components/common/Link';
import AddressSearchInput from "components/common/AddressSearchInput";
import GoogleMap from "components/common/GoogleMap";
import { LinkAnchor } from 'components/common/LinkAnchor';

import LocalMessaging from 'components/cards/LocalMessaging';
import FlybuysCompetition from 'components/cards/FlybuysCompetition';
import AwardsInfo from 'components/cards/AwardsInfo';
import ProductHighlight from 'components/cards/ProductHighlight';
import SignupNgInfo from "components/cards/SignupNgInfo";
import ProductsInfo from "components/cards/ProductsInfo";
import SignupLpgInfo from "components/cards/SignupLpgInfo";
import CarbonOffsetInfo from "components/cards/CarbonOffsetInfo";
import RacInfo from "components/cards/RacInfo";
import FlybuysInfo from "components/cards/FlybuysInfo";
import LpgCyclinderInfo from "components/cards/LpgCyclinderInfo";
import LpgOrderInfo from "components/cards/LpgOrderInfo";
import KwikGasInfo from "components/cards/KwikGasInfo";
import ManualAddressCheckInfo from "components/cards/ManualAddressCheckInfo";
import NewConnectionInfo from "components/cards/NewConnectionInfo";
import AddressSearchMultipleMatches from "components/cards/AddressSearchMultipleMatches";
import BlogCarousel from "components/BlogCarousel";
import NgRacPromoInfo from "components/cards/NgRacPromoInfo";

function ServiceAvailabilityPage(props) {
    
    const initSkipVerification = props.location.state !== null && props.location.state !== undefined &&
        props.location.state.skipVerification !== undefined && props.location.state.skipVerification === true;

    const [addressSearchApiResponse, setAddressSearchApiResponse] = useState(null);
    const [isAddressSearchApiInprogress, setIsAddressSearchApiInprogress] = useState(false);
    const [validatedAddress, setValidatedAddress] = useState(null);
    const [searchResults, setSearchResults] = useState(getSearchResults(null));
    const [skipVerification, setSkipVerification] = useState(initSkipVerification);
    const [skipVerificationOnLoad, setskipVerificationOnLoad] = useState(false);
    const [isPromoRac50Active, setIsPromoRac50Active] = useState(true);
  
	useEffect(() => {

        window.scrollTo(0, 0);
  
        const currentDate = new Date();
	    // racbonus 50
		const promoRac50Start = process.env.RACBONUS50_START.split('-');
		const promoRac50End = process.env.RACBONUS50_EXPIRE.split('-');
			
		const promoRac50StartDate = new Date(promoRac50Start[0], promoRac50Start[1]-1, promoRac50Start[2]);
		const promoRac50EndDate = new Date(promoRac50End[0], promoRac50End[1]-1, promoRac50End[2], promoRac50End[3] ? promoRac50End[3] : '', promoRac50End[4] ? promoRac50End[4] : '');
	
		const isPromoRac50Started = isAfter(currentDate, promoRac50StartDate);
		const isPromoRac50Ended = isAfter(currentDate, promoRac50EndDate);
	
		if (!isPromoRac50Started || isPromoRac50Ended) {
			setIsPromoRac50Active(false);
		}   
	}, []);
    
    // address is not specified
    if (props.location.state === null || props.location.state === undefined || 
        props.location.state.address === undefined || props.location.state.address.length === 0) {
        console.log("Address is not entered, redirecting to homepage.");       
        return <Redirect to="" noThrow />    
    }   
    else if (!initSkipVerification && !skipVerificationOnLoad) {
        
        let searchAddress = props.location.state.address;
        const isAutoComplete = props.location.state.isAutoComplete;

        runAddressSearch(searchAddress, skipVerificationOnLoad, isAutoComplete);
                
    }
    
    function runAddressSearch(searchAddress, skipVerificationOnLoad, isAutoComplete){
        if (!isAddressSearchApiInprogress && (addressSearchApiResponse === null || validatedAddress !== searchAddress)) {
            GtmHelper.sendFormComplete('Address search');
            setIsAddressSearchApiInprogress(true);

            // console.log('searching address:', searchAddress);
            const cookieName = process.env.ADDRESS_COOKIE_NAME;
    
            // console.log('existing address cookie value is');
            const cookieValue = getCookieValue(cookieName);
            // console.log(cookieValue);
    
            // console.log('removing cookie')
            deleteCookie(cookieName);
    
            // console.log('set address cookie');
            //-- passing 1 day in expiry
            setCookie(cookieName, searchAddress, 1);
            
            // validate address
            fetch(`${process.env.ADDRESS_SEARCH_URL}${searchAddress}`)  
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    return Promise.reject(response.status);
                } 
            })            
            .then(apiResponse => {      
                apiResponse.isOk = true; 
                // console.log(searchAddress); 
                // console.log(apiResponse);                

                setValidatedAddress(searchAddress);
                setAddressSearchApiResponse(apiResponse);
                setSearchResults(getSearchResults(apiResponse));                      
                setIsAddressSearchApiInprogress(false);                            
            
                const count = apiResponse!==undefined && apiResponse!==null && apiResponse.data!==undefined && apiResponse.data !== null ? apiResponse.data.length : 0;
                const postCode = count === 1 ? apiResponse.data[0].postcode : '';
                const suburb = count === 1 ? apiResponse.data[0].suburb : '';
                let availability = 'Unknown';

                if (count <= 1) {
                    if(window!==undefined && window!==null){
                        window.scrollTo(0,0)
                    }

                    // set up custom dimensions 
                    const cdsMap = new Map();

                    cdsMap.set('cd-postcode', postCode);
                    cdsMap.set('cd-suburb', suburb);
                    
                    if (apiResponse.status === 'FoundAddress') {
                        availability = 'NG';
                    }
                    else if (apiResponse.status === 'NotAvailable' && count === 1) {
                        const state = apiResponse.data[0].state;
                        availability = (state === 'WA' || state === 'NT') ? 'LPG' : 'No Service';
                    }
                    cdsMap.set('cd-availability', availability);

                    GtmHelper.sendCustomDimensions(cdsMap);
                }
                GtmHelper.sendAddressSearchValidation(apiResponse.status, count);

                // set up custom dimensions GA4
                GtmHelper.sendGA4AddressSearch(apiResponse.status, count === 0 ? '0' : count, isAutoComplete ? 'true' : 'false', postCode, suburb, availability)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                GtmHelper.sendGA4Error(`Address search error`);

                // stop re-trying same address that error                            
                setValidatedAddress(searchAddress); 
                setAddressSearchApiResponse({ isOk: false, status: error });                      

                setIsAddressSearchApiInprogress(false);
            });            
        }
    }

    function getMultipleAddressOptionTypes(apiResponse) {
        let optionTypes = [];

        if (apiResponse != null && apiResponse.data !== null && apiResponse.data.length > 0) {
            optionTypes = apiResponse.data.map(x => { return { label: x.formatted, value: x.formatted }; });
        }
        optionTypes.push({ label: 'None of these addresses are correct', value: 'None' });        
        return optionTypes;
    }

    function getSearchResults(apiResponse, isAddressUnknown=false) {
        let searchResults = {       
            hasApiResponse: false,
            isAddressUnknown: isAddressUnknown,
            isNoServiceAvailable: false,
            isMultipleAddress: false,
            isNgAvailable: false,
            isLpgAvailable: false,
            showRac: false,
            addressState: null,
            tileCardData: null
        };

        if (apiResponse != null) {
            searchResults.hasApiResponse = true;
            if (apiResponse.status !== 'NoMatches' && apiResponse.data != null) {
                if (apiResponse.status === 'FoundAddress') {

                    // console.log('FoundAddress');

                    searchResults.isNgAvailable = true;
                    searchResults.addressState = apiResponse.data[0].state;
                    searchResults.showRac = true;  
                    searchResults.tileCardData = tileCardData_ng;               
                }
                else if (apiResponse.status === 'NotAvailable' && (apiResponse.data[0].state === 'WA' || apiResponse.data[0].state === 'NT')) {                            

                    // console.log('NotAvailable');

                    searchResults.isLpgAvailable = true;
                    searchResults.addressState = apiResponse.data[0].state;
                    searchResults.showRac = apiResponse.data[0].state === 'WA';     
                    searchResults.tileCardData = tileCardData_lpg;
                }
                else if (apiResponse.status === 'MultipleMatches') {                                               
                    searchResults.isMultipleAddress = true;
                }
                else {
                    searchResults.isNoServiceAvailable = true;
                }
            }                    
            else {                        
                searchResults.isAddressUnknown = true;
            }
        }

        return searchResults;
    }

    function handleSkipVerification() {
        //setSkipVerification(true);
        setSearchResults(getSearchResults(null, true));
        if(window!==undefined && window!==null){
            window.scrollTo(0,0)
        }
    }

    function onSelection(address, skipVerification){
        // console.log('address is' + address);
        // console.log('skipVerification is ' + skipVerification);
        setskipVerificationOnLoad(true);
        runAddressSearch(address,true);
    }

    return (
       
        <> 
            <SEO title="Kleenheat" meta={[{name:"robots", content:'noindex'}]} />        
            <Header />
            <Layout>       

                { /* API in progress - display loading indicator */ }
                {isAddressSearchApiInprogress &&
                    <LayoutPanel background="mer-bg--gs-grey-lighter"
                                 border="mer-border--bottom"
                                 padding="mer-pt-ju mer-pb-lg"
                                 col="d-flex mer-vh-100 mer-vh-header">

                        <div className="container align-self-center">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-10 mer-text--size-lg">
                                    <div className="content text-center">
                                        <div className="mer-loading-spinner" role="alert" aria-busy="true">
                                            <p>Checking gas availability at your address</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LayoutPanel>
                }

                { /* API failed */ }
                {!isAddressSearchApiInprogress && addressSearchApiResponse !== null && !addressSearchApiResponse.isOk &&
                    <LayoutPanel background="mer-bg--gs-grey-lighter"
                                border="mer-border--bottom"
                                padding="mer-pt-ju mer-pb-lg">
                        <div className="container" >
                            <div className="row align-items-center justify-content-center">
                                <div className="col-10 mer-text--size-lg">
                                    <div className="content text-center">
                                        <span className="mer-typography--headline5 mb-1 d-block">{(skipVerificationOnLoad?validatedAddress:props.location.state.address)}</span>
                                        <span className="mer-typography--headline5 mb-1 d-block">
                                            Sorry, an error occurred. Please&nbsp;
                                            <LinkAnchor to="/service-availability#search-again" title="try again" stripHash={true} offset={-100} />
                                            &nbsp;to check gas availability at your address, or call us on&nbsp;                                                                                
                                            <a href="tel:132180">                                            
                                                <span>13 21 80</span>
                                            </a>                                        
                                        </span>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LayoutPanel>
                }

                { (searchResults.isAddressUnknown || skipVerification) &&
                    <LayoutPanel background="mer-bg--gs-grey-lighter"
                                border="mer-border--bottom"
                                padding="mer-pt-ju mer-pb-lg">
                        <div className="container" >
                            <div className="row align-items-center justify-content-center">
                                <div className="col-10 mer-text--size-lg">
                                    <div className="content text-center">
                                        <span className="mer-typography--headline5 mb-1 d-block">{(skipVerificationOnLoad?validatedAddress:props.location.state.address)}</span>
                                        <h2 className="mer-typography--headline1 mt-0">We couldn't find your address.</h2>
                                        <p>
                                            <LinkAnchor to="/service-availability#search-again" title="Search again?" stripHash={true} offset={-100} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LayoutPanel>
                }

                { !isAddressSearchApiInprogress && searchResults.isMultipleAddress && 
                    <LayoutPanel background="mer-bg--primary-cyan"
                        padding="mer-pt-ju mer-pb-lg">
                            <AddressSearchMultipleMatches
                                fieldName = 'addressSearch_addressSelected'
                                optionTypes = {getMultipleAddressOptionTypes(addressSearchApiResponse)}
                                addressSearchedPreviously = {validatedAddress} 
                                onSkipVerification = {handleSkipVerification}
                                onSelection = {onSelection}/>
                    </LayoutPanel>
                }

                { (searchResults.isNgAvailable || searchResults.isLpgAvailable || searchResults.isNoServiceAvailable) 
                && addressSearchApiResponse!=null && addressSearchApiResponse.data!=null && addressSearchApiResponse.data.length>0 && !searchResults.isMultipleAddress &&
                <>
                    { /* map */ }
                    <GoogleMap 
                        address={addressSearchApiResponse.data[0].formatted}                     
                        elementUnderAddress= {
                            <>
                                <h2 className="mt-0 mer-typography--headline1">{ searchResults.isNgAvailable ? 'Natural gas is available.' : (searchResults.isLpgAvailable ? 'LPG cylinders are available.' : 'Outside service area.')}</h2>
                                { !searchResults.isNoServiceAvailable && 
                                    <div className="mer-button-group justify-content-center">
                                        <Link text={ searchResults.isNgAvailable ? 'Sign up' : 'Sign up & order'} 
                                            link="/sign-up"  
                                            linkClass="mer-button mer-button--primary"
                                            />
                                    </div>                     
                                }           
                            </>
                        }    
                    />

                    { /* all products */ }
                    { !searchResults.isNoServiceAvailable && !searchResults.isMultipleAddress &&
                        <LayoutPanel background="mer-bg--gs-grey-lighter"
                                border="mer-border--bottom"
                                padding="mer-py-sm">
                            <div className="container">
                            <div className="row justify-content-center">
                                <TileCard data={searchResults.tileCardData} />
                            </div>
                            </div>
                        </LayoutPanel>
                    }

                    { searchResults.isNoServiceAvailable && !searchResults.isMultipleAddress && 
                        <LayoutPanel background="mer-bg--ui-light"
                                    border="mer-py-de"
                                    padding="mer-py-sm">
                            <div className="container">
                                <div className="row mer-panel justify-content-center">
                                    <div className="col-10 col-xl-5 col-md-6 col-sm-7 content text-center">
                                        <h3>Sorry, we don't supply this area</h3>
                                        <p>We currently only supply gas in Western Australia and the Northern Territory. Find a local gas provider on the <a href="https://www.aer.gov.au/consumers/who-is-my-distributor">Energy Networks Australia website</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </LayoutPanel>

                    }
                </>
                }

                { /* product highlight */ }
                { searchResults.isNgAvailable && 
                    <>
                        <SignupNgInfo ctaLinkAnchor={true}
                                      theme="mer-theme--cyan"
                                      background="mer-bg--primary-cyan"
                                      padding="mer-py-lg" />

                        <ProductHighlight panelBackground="mer-bg--ui-light"
                                          panelPadding="mer-pt-lg" />

                        <ProductsInfo panelBackground="mer-bg--ui-light"
                                      panelPadding="mer-py-lg" />
                    </>
                }
                { searchResults.isLpgAvailable && <SignupLpgInfo id="offer" /> }

                { /* product additional items */ }
                { searchResults.isLpgAvailable && 
                    <LpgOrderInfo background="mer-bg--ui-light" 
                                  padding="mer-pt-lg mer-pb-de" /> 
                }
                { searchResults.showRac && 
                    <RacInfo panelBackground="mer-bg--ui-light"
                             panelPadding="mer-py-de"
                             ngAvailable={searchResults.isNgAvailable} 
                             lpgAvailable={searchResults.isLpgAvailable} /> 
                }
                { searchResults.isNgAvailable && 
                    <React.Fragment>

                        <FlybuysInfo panelBackground="mer-bg--ui-light"
                                     panelPadding="mer-py-de" />

                        <CarbonOffsetInfo  panelBackground="mer-bg--ui-light"
                                           panelPadding="mer-pt-de mer-pb-ju" />

                        <NgRacPromoInfo
						    theme={`mer-theme--cyan${isPromoRac50Active ? ' d-block': ' d-none'}`}
                            background="mer-bg--primary-cyan"
                            padding="mer-py-lg" />

                        <AwardsInfo panelBackground="mer-bg--gs-grey-lighter" 
                    	        	panelPadding="mer-py-de" />
                    </React.Fragment>
                }
                { !searchResults.isNoServiceAvailable && !searchResults.isMultipleAddress && searchResults.addressState && !searchResults.isNgAvailable && 
                    <LocalMessaging addressState={searchResults.addressState}
                                    panelBackground="mer-bg--ui-light"
                                    panelPadding="mer-pt-de mer-pb-ju" />
                }
                { (searchResults.isAddressUnknown || skipVerification) && !searchResults.isMultipleAddress && 
                    <>
                      <ManualAddressCheckInfo/>
                        <NewConnectionInfo/>
                    </>
                }

                { /* alternative product */ }
                { (searchResults.isNgAvailable || searchResults.isAddressUnknown || skipVerification) && !searchResults.isMultipleAddress && <LpgCyclinderInfo panelBackground="mer-bg--ui-light" 
                    	        	                   panelPadding="mer-py-lg" /> }
                { searchResults.isLpgAvailable && <KwikGasInfo/> }
                            
                { !isAddressSearchApiInprogress && !searchResults.isMultipleAddress &&  
                    <LayoutPanel background="mer-bg--primary-cyan"
                                theme="mer-theme--cyan"
                                padding="mer-py-lg"
                                id="search-again">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-10 col-lg-8 mer-text--size-lg">
                                    <div className="content text-center" >
                                        <h2>Search again?</h2>
                                    </div>
                                    
                                    <AddressSearchInput 
                                        searchButtonText='Find gas deals'
                                        label='Supply address'
                                        padding="mer-mt-de"
                                        useTrigger={true}
                                        onSelection={onSelection}/>

                                </div>
                            </div>                
                        </div>
                    </LayoutPanel>                               
                }

                { searchResults.isNoServiceAvailable &&                     
                    <BlogCarousel />
                }
                
            </Layout>

            <Footer />
        </>
    
    );
}

export default ServiceAvailabilityPage
