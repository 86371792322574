import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';

import productKwiKGas from 'images/product-kwikgas.png';

function KwikGasInfo (props) {

  return (
    <LayoutPanel background="mer-bg--gs-grey-lighter"
                  padding="mer-py-lg">
        <div className="container">
            <div className="row mer-panel">

              <div className="col-10 col-xl-5 col-md-6 col-sm-7 content">
                  <h2>Get your gas on the go</h2>
                  <p>Kleenheat Kwik-Gas is the quick and easy option for all your barbecue, outdoor heating and camping needs. Simply swap your empty cylinder for Kwik-Gas and away you go.</p>
                  <div className="mer-panel__actions mer-button-group">
                      <a href="/locations" className="mer-button mer-button--primary">Find a retailer</a>
                  </div>
              </div>

              <div className="col-10 col-lg-3 col-sm-4 offset-sm-1 mer-panel__images">
                  <img src={productKwiKGas} 
                       className="mer-panel__image"
                       alt="Kwik-Gas cylinder" />
              </div>

            </div>
        </div>
    </LayoutPanel>
  )
}

export default KwikGasInfo;