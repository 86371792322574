import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import tenMILLP10KP from "images/flybuys-win/flybuys-10m-10k.svg";
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';

function flybuysCompetition (props) {

  return (
    <LayoutPanel background={props.panelBackground}
                 padding={props.panelPadding}
                 {...props}>
        <div className="container">
            <div className="row mer-panel justify-content-around align-items-middle flex-column">

              <div className="col-10 col-sm-8 col-lg-4 mer-panel__images d-flex align-items-center">
                <img className="mer-panel__image"
                     src={tenMILLP10KP}
                     alt="Flybuys points" />
              </div>

              <div className="col-12 col-sm-10 col-lg-8 text-center">
                <div className="content">
                  <h2 className="my-0 mer-typography--headline3">Your chance to win a share of <span className="mer-text--no-wrap">10 million</span> <span className="mer-text--no-wrap">Flybuys points</span>.<sup>^</sup></h2>
                  <p className="mer-typography--headline5"><strong>Switch to Kleenheat and enjoy added value through our partnership with Flybuys. Click the link below for details on how to enter and redeem this offer.</strong></p>

                  <p className="mb-0 mer-typography--body2">
                    <sup>^</sup>Offer is available to new customers only.&nbsp;
                    <LinkModal 
                      modalContents={modalContents}
                      useModal="true"
                      title="Flybuys offer terms and conditions"
                      linkClass="mer-text--no-wrap"
                      displayContent="Terms and conditions apply"
                      modalTitle="Flybuys offer terms and conditions"
                      modalContentName="flybuys-win-terms"
                      okayButtonLabel="Okay"
                    />
                  </p>
                  
                  <div className="col-12 col-sm-8 col-md-6 col-lg-4 mer-panel__actions mer-button-group flex-column mx-auto">
                    <a href="/flybuys-win"
                       title="Go to Flybuys win page"
                       className="mer-button mer-button--primary">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </LayoutPanel>
    
  )
}

export default flybuysCompetition;