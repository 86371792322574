import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';

import heroMug from 'images/hero-mug.png';

function ManualAddressCheckInfo (props) {

  return (
    <LayoutPanel background="mer-bg--ui-light"
                  padding="mer-py-lg">
      <div className="container">
        <div className="row mer-panel">
            <div className="col-10 col-xl-5 col-md-6 col-sm-7 content">
                <h2>Is your property already connected to natural gas?</h2>
                <p>If you're in Western Australia and already connected to the <a href="https://www.atco.com/en-au/for-business/natural-gas/wa-gas-network/network.html">ATCO natural gas network</a>, you can sign up online and we will take care of manually confirming your address later.</p>
                <div className="mer-panel__actions mer-button-group">
                    <a href="/sign-up" className="mer-button mer-button--primary">Sign up</a>
                    <a href="/natural-gas" className="mer-button mer-button--secondary">Learn more</a>
                </div>
            </div>
            <div className="col-10 col-lg-4 col-sm-4 offset-sm-1 mer-panel__images">
                <img src={heroMug} 
                     className="mer-panel__image"
                     alt="I love Western Australia mug" />
            </div>
        </div>
      </div>
    </LayoutPanel>
  )
}

export default ManualAddressCheckInfo;