import React, { useState, useEffect } from 'react';
import Carousel from 'components/common/Carousel';
import Layout from 'components/layout/layoutPanel';
import Link from 'components/common/Link';
import fetchBlogData from 'constants/fetchBlogData';

function BlogCarousel () {
    
    const [blogPosts, setPosts] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setPosts( await fetchBlogData('/apis/seahorse/recents?count=4'));
        };
     
        fetchData();
    }, []);

    return (
        <React.Fragment>
        {
            !!(Array.isArray(blogPosts) && blogPosts.length && blogPosts.length>0) &&
            <Layout background="mer-bg--gs-grey-lighter"
                    padding="mer-py-lg">
                
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-5 text-center">
                            <h3 className="mb-0">Latest from the blog</h3>
                        </div>
                    </div>
                </div>

                <Carousel equalHeight={true} 
                          data={blogPosts}
                          isRunTime={true}/>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <div className="mer-button-group justify-content-center">
                                <Link text="Visit the Kleenheat blog" 
                                    link="/blog"
                                    linkClass="mer-button mer-button--primary" />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        }
       </React.Fragment> );
}

export default BlogCarousel;