import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import GoogleMapReact from 'google-map-react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Layout from 'components/layout/layoutPanel';
import { LinkAnchor } from 'components/common/LinkAnchor';

const createMapOptions = {
    panControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    scrollwheel: false,
    zoomControl: false,
    styles: [
        {
            "featureType": "administrative.land_parcel",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "administrative.neighborhood",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "poi.business",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "transit",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "water",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "off"
            }]
        }
    ]
};

const LocationPin = ({ text }) => <div>{text}</div>;

const GoogleMap = ({address, zoomLevel, showPin, mapOptions, elementUnderAddress}) => {
    const [location, setLocation] = useState(0);
    useEffect(() => { 
        
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                // console.log('Successfully got latitude and longitude', { lat, lng });
                setLocation({address, lat, lng});
            })
            .catch(err => {
                console.log('error:', err);
            });                          
      }, [address]);

    return (
        location != 0 &&              
            <Layout background="mer-bg--ui-light"
                    padding="mer-pt-ju mer-pb-lg"
                    border="mer-border--bottom"
                    col="mer-maps mer-maps-overlay"
                    >

                <div className="container mer-maps__contents">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-10 mer-text--size-lg">
                            <div className="content text-center">
                                <span className="mb-1 mer-typography--headline5 d-block">{address}</span>
                                {elementUnderAddress}  
                                <p>
                                    <LinkAnchor to="/service-availability#search-again" title="Search again?" stripHash={true} offset={-100} />
                                </p>   
                            </div>                       
                        </div>
                    </div>
                </div>

                <div className="mer-maps__map">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_KEY }}
                        center={location}
                        defaultZoom={zoomLevel}        
                        options={mapOptions} 
                    >
                        {showPin &&
                            <LocationPin
                                lat={location.lat}
                                lng={location.lng}
                                text={location.address}
                            />
                        }
                    </GoogleMapReact>       
                </div>     
            </Layout> 
    );  
}

GoogleMap.defaultProps = {    
    zoomLevel: 14,
    mapOptions: createMapOptions,
    showPin: false,
    elementUnderAddress: <></>
};

GoogleMap.propTypes = {
    address: PropTypes.string.isRequired,
    mapOptions: PropTypes.object,
    zoomLevel: PropTypes.number,
    showPin: PropTypes.bool,
    elementUnderAddress: PropTypes.node
};

export default GoogleMap;