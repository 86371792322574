import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Svg, LinkModal } from 'kh-common-components';
import AddressSearchInput from "components/common/AddressSearchInput";
import FormErrors from 'components/common/FormErrors';
import { modalContents } from 'constants/modal-content';

function AddressSearchMultipleMatches ({fieldName, optionTypes, addressSearchedPreviously, onSkipVerification, onSelection}) {
  
    const [selectedOption, setSelectedOption] = useState('');
    const [isNoneSelected, setIsNoneSelected] = useState(false);
    const [skipVerification, setSkipVerification] = useState(false);
    const [searchAgainAddress, setSearchAgainAddress] = useState('');
    const [errorList, setErrorList] = useState([]); //-- the assumption in this card is that there will be only one error at any time
    const supplyAddressMessage = 'Supply address is required';
    const addressMessage = 'Please select a matching address';
    const addressRetryFieldName = 'addressSearch_addressRetry';

    function onChange(e) {               
        // e.preventDefault();
        const value = e.target.value;        
        // console.log(value); 
        setSelectedOption(value);

        if(hasError()){
            setError(true,addressMessage, fieldName);
        }
        
        if (value === 'None') {
            setIsNoneSelected(true);
            setSearchAgainAddress('');
        }
        else {
            setIsNoneSelected(false);
            setSearchAgainAddress(value);
            // navigate(`/service-availability`, { state: { address : value }, replace: true });      
        }
    }

    function onSkipVerificationChange(e) {
        const value = e.target.value;
        setSkipVerification(value === 'true');
        if(hasError()){
            setError(true,supplyAddressMessage, fieldName);
        }
    }

    function onAddressChange(address) {  
        if(hasError()){
            setError(true,supplyAddressMessage, addressRetryFieldName);
        }
        setSearchAgainAddress(address);      
    }

    function setError(isResolved, errorMessage, errorFieldName){
        setErrorList([{isResolved:isResolved, errorStr: errorMessage, fieldName:errorFieldName}])
    }

    function hasError(){
        return errorList!==null && errorList!==undefined && errorList.length>0;
    }

    function showAddressError(){
        return errorList!==null && errorList!==undefined && errorList.length>0 && errorList[0].isResolved===false && !isNoneSelected;
    }

    function showSupplyAddressError(){
        return errorList!==null && errorList!==undefined && errorList.length>0 && errorList[0].isResolved===false && isNoneSelected;
    }

    function handleClick(e) {

        e.preventDefault();
        if (skipVerification) {
            onSkipVerification();
        }
        else {
            let address = skipVerification ? addressSearchedPreviously : searchAgainAddress;
            if(address===undefined || address===null || address.replace(/ /g, '').length===0){
                if(!isNoneSelected){ 
                    setError(false, addressMessage, fieldName);
                }else{
                    setError(false, supplyAddressMessage, addressRetryFieldName);
                }
            }
            else
            {
                // console.log('here');
                onSelection(address, skipVerification);
                //navigate(`/service-availability`, { state: { address : address, skipVerification: skipVerification } });        
            }
        }
    }

    return (
    <div className="container">
        <div className="row justify-content-md-center">
            <div className="col-12 col-xl-5 col-lg-6 col-md-7 col-sm-8">   
                <div className="mer-card mer-card-page mer-card-page--action-absolute mdc-card">
                    <div className="mer-card-primary-action">
                        <FormErrors errorlist={errorList}/>

                        <fieldset>
                            <h4 className="mer-color--primary-royal mt-0 mb-3">
                                We found several possible matches for your address
                            </h4>

                            <h5 className="mt-0 mb-3 mer-color--primary-blue mer-text--size-lg mer-font--weight-semi-bold" data-hj-suppress>
                                {addressSearchedPreviously}
                            </h5>
                            <p className="mt-2">Please check if one of the options we've found below matches your address.</p>
                            
                            <fieldset className="mt-5">
                                <legend>Select the correct address</legend>
                                <div className="row">
                                    <div className={showAddressError()?"col-12 mer-form__field mer-form__field--status-critical":"col-12 mer-form__field"}>
                                        <div className="mer-form-fields mer-form-fields--flex-column">
                                            {
                                                optionTypes.map(function (optionType, i) {
                                                    const rowFieldName = i === 0
                                                        ? fieldName // use fieldname here so that error summary link can jump to this field
                                                        : `po-${fieldName}-${i}`;

                                                    // console.log(Object.prototype.toString.call(optionType.label));

                                                    return (
                                                        <div className="mdc-form-field" key={rowFieldName}>
                                                            <div className="mdc-radio">
                                                                <input className="mdc-radio__native-control" 
                                                                    type="radio"
                                                                    id={rowFieldName} 
                                                                    name={fieldName} 
                                                                    value={optionType.value}
                                                                    //aria-label={optionType.label}
                                                                    // do not change checked to defaultchecked as it will break AddressSearchMultipleMatchesCard 
                                                                    checked={selectedOption === optionType.value}
                                                                    onChange={onChange} 
                                                                    />
                                                                <div className="mdc-radio__background">
                                                                <div className="mdc-radio__outer-circle"></div>
                                                                <div className="mdc-radio__inner-circle"></div>
                                                                </div>
                                                                    <label
                                                                        htmlFor={rowFieldName}
                                                                        aria-hidden="true">
                                                                        {Object.prototype.toString.call(optionType.label) === "[object String]" &&
                                                                            <span dangerouslySetInnerHTML={{ __html: optionType.label }}></span>
                                                                        }
                                                                        {Object.prototype.toString.call(optionType.label) === "[object Object]" &&
                                                                            optionType.label
                                                                        }
                                                                    </label>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            {showAddressError() &&
                                                        <span className="mer-form-field__status-descriptor  ml-4 mb-2 pl-3">{addressMessage}</span>}
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            { isNoneSelected &&
                                <React.Fragment>                                  
                                    
                                    <fieldset>
                                        <legend>How would you like to proceed?</legend>
                                        <div className="mer-form-toggle row">
                                            <div className="mer-form__field col-12">
                                                <div className="mer-form-fields" role="radiogroup">
                                                    <div className="mer-form-toggle__item" role="radio">
                                                        <input type="radio"
                                                            name="addressSearch.skipVerification"
                                                            id="pad-search-again"
                                                            value="false"
                                                            onChange={onSkipVerificationChange}
                                                            checked={!skipVerification}
                                                        />
                                                        <label htmlFor="pad-search-again">Search again</label>
                                                    </div>

                                                    <div className="mer-form-toggle__item" role="radio">
                                                        <input type="radio"
                                                            name="addressSearch.skipVerification"
                                                            id="pad-skip-verification"
                                                            value="true"
                                                            onChange={onSkipVerificationChange}
                                                            checked={skipVerification}
                                                        />
                                                        <label htmlFor="pad-skip-verification">Skip verification</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {!skipVerification &&
                                            <div className="row">                                    
                                                <div className={showSupplyAddressError()?"col-12 mer-form__field mer-form__field--status-critical":"col-12 mer-form__field"} id={addressRetryFieldName}>
                                                <AddressSearchInput        
                                                    initialAddress={searchAgainAddress}                                         
                                                    label={`Enter the address you'd like gas supplied to`}
                                                    showLabel="true"
                                                    onAddressChange={onAddressChange}
                                                    isRequired={true}
                                                    useTrigger={true}
                                                    onSelection={onSelection}   
                                                />
                                                {showSupplyAddressError() &&
                                                <span className="mer-form-field__status-descriptor">{supplyAddressMessage}</span>}

                                                <span className="mer-form-field__descriptor">
                                                    <LinkModal
                                                        modalContents={modalContents}
                                                        useModal="true"
                                                        linkClass="mer-svg-content mer-svg-content--size-xs"
                                                        icon="question-circle"
                                                        iconClass="mer-svg-container mer-svg-container--align-center mer-svg-container--width-xs mer-fill--primary-blue"
                                                        displayContent="Need a hint to help find your address?"
                                                        title="Need a hint to help find your address?"
                                                        modalContentName="hint_find_address"
                                                        okayButtonLabel="Close"
                                                    />
                                                </span>

                                                </div>

                                            </div> 
                                        }

                                        {skipVerification &&
                                            <React.Fragment>
                                                <p className="mer-svg-content mer-svg-content--size-md mt-4">
                                                <Svg title="Tick"
                                                    col="mer-svg-container mer-svg-container--size-md"
                                                    icon="tick-circle" />
                                                    If your address looks right, you can skip verification. We'll give you some options, but you will be able to proceed.
                                                </p>
                                                
                                            </React.Fragment>
                                        }

                                    </fieldset>
                                </React.Fragment>
                            }




                        </fieldset>
                    </div>

                    <div className="mer-card-action-container mdc-card__actions mer-card-action--justify-end">
                        <button onClick={handleClick} 
                                className="mer-button mer-button--primary mdc-card__action mdc-card__action--button">
                                <span className="d-none d-sm-block mer-text--no-wrap">Next</span>
                                <Svg title="Next"
                                    col="mer-svg-container--size-xs d-sm-none"
                                    icon="chevron-right" />
                        </button> 
                    </div>
                </div>  
            </div>  
        </div>
    </div>
    )
}

AddressSearchMultipleMatches.propTypes = {
  fieldName: PropTypes.string.isRequired,
  optionTypes: PropTypes.array.isRequired,
  addressSearchedPreviously: PropTypes.string.isRequired,
  onSkipVerification: PropTypes.func.isRequired
};

export default AddressSearchMultipleMatches;